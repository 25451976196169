.remove-arrow > .dropdown-toggle::after {
    display: none !important; 
  }

.profile-image {
  height: 40px;
  width: 40px;
}

.auth-button-container {
  height: 40px;
  width: 40px;
}

#accountButton-loggedOut-dropdown {
  height: 40px;
  align-items: center;
  display: flex;
}

.remove-focus:focus {
  background-color: #ffff !important;
}

.remove-focus:hover {
  background-color: #ffff !important;
  color: black !important;
}

.auth-spinner {
  height: 40px !important;
  width: 40px !important;
}