.logo-setup {
    display: flex;
    align-items: baseline;
}

.logo-title {
    padding-right: 8px;
}

.logo-content {
    padding-left: 8px;
    font-size: 16px;
}